import handleRequest from 'sagas/handleRequest'
import { all, call, put, select } from 'redux-saga/effects'
import { push, LOCATION_CHANGE } from 'react-router-redux'
import { readAsDataURL } from 'promise-file-reader'

import { takeLatest } from 'utils/effects'
import { formatDatesHo, transformDate } from 'utils/date'
import { checkboxArrayToObject } from 'utils/job'
import client from 'services/httpClient/engineClient'
import billingClient from 'services/httpClient/billingClient'
import syliusClient from 'services/httpClient/syliusClient'
import notify from 'sagas/notify'
import { initializeApp, getApp, getApps } from 'firebase/app'
import { getDatabase, ref, onValue } from 'firebase/database'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import store from 'index'
import { firebaseTrackingConfig } from 'config'
import { formatVerifyConditionsData } from 'helpers/form'

import {
  JOBS_REQ,
  JOB_ACCEPT_TRANSITION_REQ,
  JOB_DECLINE_TRANSITION_REQ,
  UPDATE_JOB,
  GET_RECEIPT_JOB,
  VALIDATE_RECEIPT,
  GET_RECEIPT_SIGNATURE,
  GET_INVOICE_JOB,
  jobs,
  jobAcceptTransition,
  jobDeclineTransition,
  closeAll,
  GET_ALL_JOBS,
  GET_JOB,
  getJob,
  setAllJobsIsLoading,
  getAllJobs,
  updateJob,
  validateReceipt,
  getReceiptJob,
  getReceiptJobFile,
  getReceiptSignature,
  getJobCustomer,
  getInvoiceJob,
  transitionInvoiceJob,
  TRANSITION_INVOICE_JOB,
  firmDetails,
  AUTH_LOGIN,
  GET_PRO_PHONE_NUMBER,
  getProPhoneNumber,
  SHARE_JOB_INFOS,
  shareJobInfos,
  SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_ONE,
  SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_TWO,
  SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_THREE,
  OPEN_RECEIPT_JOB_FILE,
  GET_RECEIPT_JOB_FILE,
  UPLOAD_HO_PICTURE_TO_CLOUDINARY,
  uploadHoPictureToCloudinary,
  SAVE_TRACKING_FILL_IN_HO_INFORMATION,
  DELETE_JOB_PICTURE,
  deleteJobPicture,
  saveTrackingFillInHoInformation,
  MULTIPLE_UPLOAD_HO_FILE,
  multipleUploadHoFile,
  SUBMIT_NEW_TIMESLOTS_FORM,
  submitNewTimeslotsForm,
  jobClientStepperTransition,
  JOB_CLIENT_STEPPER_TRANSITION_REQ,
  SUBMIT_JOB_CLIENT_FORM_STEP_ONE,
  SUBMIT_JOB_CLIENT_FORM_STEP_TWO,
  SUBMIT_JOB_CLIENT_FORM_STEP_THREE,
  SUBMIT_JOB_CLIENT_FORM_STEP_FOUR,
  SUBMIT_JOB_CHECKIN_FORM_STEP_ONE,
  SUBMIT_JOB_CHECKIN_FORM_STEP_TWO,
  submitJobCheckinFormStepTwoReq,
  SUBMIT_JOB_CHECKIN_FORM_STEP_THREE,
  submitJobCheckinFormStepThreeReq,
  SUBMIT_NEW_DELIVERY_DATE_REQ,
  submitNewDeliveryDate,
  GET_STEP_FOUR_FORM_QUESTIONS,
  getStepFourFormQuestions,
  submitJobClientForm,
  submitJobClientFormPackageCheckin,
  GET_RECEIPT_TYPES,
  getReceiptTypes,
  SEND_RESERVES,
  sendReserves,
  getReceiptWithReserves,
  setOpenYousignIsLoading,
} from '../actions'
import { fromPro, fromFirm, fromRouting, fromJob } from 'store/selectors'
import {
  JOB_PERCEIVED_STATUSES,
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_DECLINED,
  JOBS_PER_PAGE,
  JOBS_PER_PAGE_PENDING_START,
  JOB_STATUS_ORDER,
  STATUS_ORDER_DEFAULT,
  HTTP_CONFLICT_CODE,
  STATUS_JOB_IN_PROGRESS,
  SPECIFIC_ROOM,
  SPECIFIC_FLOOR,
  CHECK_IN_HO_STATUS_OK,
  CHECK_IN_HO_STATUS_KO,
  PICTURE_ORIGIN_JOB_TRACKING,
} from 'constants/job'

import {
  GET_JOBS_CUSTOMER,
  getJobsCustomer,
  GET_JOB_CUSTOMER,
  setCurrentJobId,
  getJobTokenFirebase,
  GET_JOB_TOKEN_FIREBASE,
  SUBMIT_VERIFY_CHECKING_CONDITION_FORM,
  submitVerifyCheckingConditionForm,
  GET_CHECKING_CONDITION_LIST,
  getCheckingConditionList,
} from './actions'
import { DATE_FORMAT } from 'constants/dateFormat'
import {
  JOB_CLIENT_FORM_STEP_ONE_ID,
  JOB_CLIENT_FORM_STEP_TWO_ID,
  JOB_CLIENT_FORM_STEP_THREE_ID,
  JOB_CLIENT_FORM_STEP_FOUR_ID,
  JOB_CHECKIN_FORM_STEP_ONE_ID,
  VALUE_HABITATION_APARTMENT,
  WITHOUT_RESERVES,
} from 'constants/form'

function* handleJobRequestByStatusAndPage(status, page) {
  const perPage =
    status === STATUS_JOB_IN_PROGRESS
      ? JOBS_PER_PAGE_PENDING_START
      : JOBS_PER_PAGE

  yield handleRequest({
    requestActions: jobs,
    promise: call(client.get, '/engine/jobs', {
      params: {
        status,
        page,
        perPage,
        [`order[${JOB_STATUS_ORDER[status]}]`]: STATUS_ORDER_DEFAULT,
      },
    }),
    actionParams: {
      status,
    },
  })
}

function* handleUpdateJobRequest({ jobData }) {
  try {
    yield handleRequest({
      requestActions: updateJob,
      promise: call(client.put, jobData['@id'], jobData),
    })
    yield notify('', 'job.details.update.message')
  } catch (e) {
    console.error(e)
    yield notify('', 'job.details.update.error_message', 'error')
  }
}

function* handleGetAllJobs() {
  yield put(setAllJobsIsLoading(true))
  try {
    yield all(
      JOB_PERCEIVED_STATUSES.map(status =>
        handleJobRequestByStatusAndPage(status, 1),
      ),
    )
  } catch (e) {
    yield put(setAllJobsIsLoading(false))
  }
  yield put(setAllJobsIsLoading(false))
}

function* handleJobsRequest({ status, page }) {
  yield handleJobRequestByStatusAndPage(status, page)
}

function* handleAcceptJobTransitionRequest({ jobIri, data }) {
  try {
    yield handleRequest({
      requestActions: jobAcceptTransition,
      promise: call(
        client.post,
        `${jobIri}/transition/process_assignment`,
        data,
      ),
    })

    yield notify('', 'job_pro.update.accepted.message')
    yield put(push('/pro/my-account/packages/in_progress/pending_realization'))

    yield handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1)
    yield handleJobRequestByStatusAndPage(STATUS_PENDING_REALIZATION, 1)
    yield handleGetJobs({ jobIri })

    const firmId = yield select(fromPro.getFirmId)
    const isFirmPendingTestJob = yield select(
      fromFirm.isFirmPendingTestJobSelector,
      firmId,
    )
    if (isFirmPendingTestJob) {
      yield put(firmDetails.request({ id: firmId }))
    }
  } catch (e) {
    if (e.response.status !== HTTP_CONFLICT_CODE) {
      yield notify('', 'server_error', 'error')
    } else {
      yield notify('', 'job.details.missed_job.text', 'warning')
    }
    yield put(getAllJobs())
  }
}

function* handleGetJobs({ jobIri }) {
  yield handleRequest({
    requestActions: getJob,
    promise: call(client.get, jobIri),
  })
}

const RTDBLogin = customToken =>
  signInWithCustomToken(getAuth(getApp('geo')), customToken)
    .then(() => true)
    .catch(error => {
      console.error('RTDBLogin error', error)

      return false
    })

function* handleGetJobTokenFirebase({ jobId }) {
  if (getApps().length <= 1) {
    yield initializeApp(firebaseTrackingConfig, 'geo')
  }

  const loginResult = yield handleRequest({
    requestActions: getJobTokenFirebase,
    promise: call(client.get, 'engine/firebase_token'),
  })
  if (loginResult && loginResult.data && loginResult.data.token) {
    const RtdbLoginResult = yield RTDBLogin(loginResult.data.token)
    if (RtdbLoginResult) {
      const database = getDatabase(getApp('geo'))
      const geoLoc = yield ref(database, `geopoints/${jobId}`)

      onValue(geoLoc, snapshot => {
        store.dispatch({
          type: 'SET_CURRENT_GEOLOC_PRO',
          payload: snapshot.val(),
        })
      })
    }
  }
}

function* handleGetReceiptJob({ jobIri }) {
  yield handleRequest({
    requestActions: getReceiptJob,
    promise: call(client.get, `${jobIri}/receipt`),
  })
}

const handleOpenReceiptJobNewWindow = function* ({ jobIri }) {
  yield handleRequest({
    requestActions: getReceiptJobFile,
    promise: call(client.get, `${jobIri}/receipt`),
  })
}

function handleGetReceiptJobNewWindowSuccess({ payload }) {
  var binary = atob(payload.content.replace(/\s/g, ''))
  var len = binary.length
  var buffer = new ArrayBuffer(len)
  var view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }

  const link = document.createElement('a')
  const blob = new Blob([view], { type: 'application/pdf' })
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)

  if (navigator.userAgent.match('CriOS')) {
    link.setAttribute('target', 'blank')
  } else {
    link.setAttribute('download', 'receipt.pdf')
  }

  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function* handleValidateReceipt({ jobData }) {
  try {
    yield handleRequest({
      requestActions: validateReceipt,
      promise: call(client.put, jobData['@id'], jobData),
    })
    yield handleRequest({
      requestActions: getReceiptJob,
      promise: call(client.get, `${jobData['@id']}/receipt`),
    })
    yield notify('', 'job.details.validate.receipt.message')
    yield handleRequest({
      requestActions: getReceiptSignature,
      promise: call(client.get, `${jobData['@id']}/receipt/signature`),
    })
  } catch (e) {
    yield notify('', 'job.details.validate.receipt.signature.error', 'error')
  }
}

function* handleGetReceiptSignature({ jobIri }) {
  try {
    yield handleRequest({
      requestActions: getReceiptSignature,
      promise: call(client.get, `${jobIri}/receipt/signature`),
    })
  } catch (e) {
    yield notify('', 'job.details.validate.receipt.signature.error', 'error')
  }
}

function* handleGetJobCustomer({ jobId }) {
  yield handleRequest({
    requestActions: getJobCustomer,
    promise: call(client.get, `/engine/jobs/${jobId}`),
  })
}

function* handleGetJobsCustomer() {
  yield handleRequest({
    requestActions: getJobsCustomer,
    promise: call(
      client.get,
      `/engine/jobs?order[worksite.order.checkoutCompletedAt]=desc`,
    ),
  })
}

function* handleGetJobCustomerFailure(payload) {
  yield put({ type: AUTH_LOGIN.FAILURE })
  const redirectPathname = yield select(fromRouting.getPathname)
  yield put(
    push({
      pathname: '/login',
      state: { redirectPathname },
    }),
  )
  if (payload.error.response.status === 404) {
    yield notify('', 'tracking_authentication.access_denied', 'error')
    return
  }

  if (payload.error.response.status === 401) {
    return
  }

  yield notify('', 'tracking.job.api.error', 'error')
}

function* handleGetInvoiceJob({ invoiceId }) {
  yield handleRequest({
    requestActions: getInvoiceJob,
    promise: call(billingClient.get, `purchase-invoices/${invoiceId}/content`),
  })
}

function* handleTransitionInvoiceJob({ invoiceId, transition, data = {} }) {
  try {
    yield handleRequest({
      requestActions: transitionInvoiceJob,
      promise: call(
        billingClient.post,
        `/purchase-invoices/${invoiceId}/transition/${transition}`,
        data,
      ),
    })
  } catch (e) {
    yield notify('', 'job_pro.invoice.transition.error', 'error')
  }
}

function* handleDeclineJobTransitionRequest({ jobIri, data }) {
  yield put(closeAll())
  try {
    yield handleRequest({
      requestActions: jobDeclineTransition,
      promise: call(client.post, `${jobIri}/transition/process_decline`, data),
    })

    yield put(push('/pro/my-account/packages/pending_answer'))
    yield notify('', 'job.transition.decline.success_message')

    yield handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1)
    yield handleJobRequestByStatusAndPage(STATUS_JOB_DECLINED, 1)
    yield handleGetJobs({ jobIri })
  } catch (e) {
    if (e.response.status !== HTTP_CONFLICT_CODE) {
      yield notify('', 'server_error', 'error')
    } else {
      yield notify('', 'job.details.missed_job.text', 'warning')
    }
    yield handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1)
    yield handleGetJobs({ jobIri })
  }
}

function* handleLocationChange() {
  yield put(setCurrentJobId(null))
}

function* handleSubmitVerifyCheckingConditionForm({ data, jobId }) {
  const requiredProducts = yield select(fromJob.getRequiredProducts)
  yield handleRequest({
    requestActions: submitVerifyCheckingConditionForm,
    promise: call(
      client.post,
      `/engine/jobs/${jobId}/ho_checklist_items`,
      formatVerifyConditionsData(data, requiredProducts),
    ),
    actionParams: { jobId },
  })
}

function* handleSubmitVerifyCheckingConditionFormSuccess({ actionParams }) {
  yield handleRequest({
    requestActions: getJobCustomer,
    promise: call(client.get, `/engine/jobs/${actionParams.jobId}`),
  })
}

function* handleSubmitVerifyCheckingConditionFormFailure({ actionParams }) {
  yield notify('', 'tracking.job.api.error', 'error')
}

function* handleGetCheckingConditionList({ jobId }) {
  try {
    yield handleRequest({
      requestActions: getCheckingConditionList,
      promise: call(client.get, `/engine/jobs/${jobId}/ho_checklist_items`),
    })
  } catch (e) {
    yield notify('', 'server_error', 'error')
  }
}

function* handleGetProPhoneNumber({ payload }) {
  yield handleRequest({
    requestActions: getProPhoneNumber,
    promise: call(client.get, `/engine/jobs/${payload}/conversations`),
  })
}

function* handleShareJobInfos({ jobId, data }) {
  try {
    yield handleRequest({
      requestActions: shareJobInfos,
      promise: call(client.post, `/engine/jobs/${jobId}/share`, data),
    })
  } catch (e) {
    yield notify('', 'server_error', 'error')
  }
}

function handleSubmitTrackingInformationStepOne({ data, jobId }) {
  localStorage.setItem(`trackingInfoStepOne_${jobId}`, JSON.stringify(data))
}

function handleSubmitJobClientFormStepOne({ data, jobId }) {
  localStorage.setItem(
    `${JOB_CLIENT_FORM_STEP_ONE_ID}${jobId}`,
    JSON.stringify(data),
  )
}

function handleSubmitJobCheckinFormStepOne({ data, jobId }) {
  localStorage.setItem(
    `${JOB_CHECKIN_FORM_STEP_ONE_ID}${jobId}`,
    JSON.stringify(data),
  )
}

function* handleSubmitJobCheckinFormStepTwo({ jobId }) {
  const stepOne = JSON.parse(
    localStorage.getItem(`${JOB_CHECKIN_FORM_STEP_ONE_ID}${jobId}`),
  )
  const formatedData = []
  Object.keys(stepOne).forEach((val, index) => {
    const newFormatData = {
      code: val,
      status:
        stepOne[val] === true ? CHECK_IN_HO_STATUS_OK : CHECK_IN_HO_STATUS_KO,
    }
    return formatedData.push(newFormatData)
  })

  try {
    yield handleRequest({
      requestActions: submitJobCheckinFormStepTwoReq,
      promise: call(
        client.post,
        `/engine/jobs/${jobId}/ho_checklist_items`,
        formatedData,
      ),
    })
    localStorage.removeItem(`${JOB_CHECKIN_FORM_STEP_ONE_ID}${jobId}`)
    yield handleRequest({
      requestActions: getJobCustomer,
      promise: call(client.get, `/engine/jobs/${jobId}`),
    })
  } catch (e) {
    console.error(e)
    yield notify('', 'tracking.job.api.error', 'error')
  }
}

function* handleSubmitNewDeliveryDate({ jobId, newDeliveryDate }) {
  const formatedData = transformDate(
    newDeliveryDate,
    DATE_FORMAT.ISO_INITIAL_TIMEZONE,
    DATE_FORMAT.FR_LONG,
  )
  try {
    yield handleRequest({
      requestActions: submitNewDeliveryDate,
      promise: call(client.put, `/engine/jobs/${jobId}/update_delivery_date`, {
        deliveryDate: formatedData,
      }),
    })
    yield handleRequest({
      requestActions: getJobCustomer,
      promise: call(client.get, `/engine/jobs/${jobId}`),
    })
    localStorage.removeItem(`${JOB_CHECKIN_FORM_STEP_ONE_ID}${jobId}`)
    yield put(
      push({
        pathname: `/suivi/${jobId}`,
      }),
    )
  } catch (e) {
    console.error(e)
    yield notify('', 'tracking.job.api.error', 'error')
  }
}

function* handleSubmitJobCheckinFormStepThree({ jobId, data }) {
  try {
    yield handleRequest({
      requestActions: submitJobCheckinFormStepThreeReq,
      promise: call(
        client.put,
        `/engine/jobs/${jobId}/timeslots`,
        formatDatesHo(data),
      ),
    })
  } catch (e) {
    yield notify('', 'server.error', 'error')
  }

  return dates
}

function handleSubmitTrackingInformationStepTwo({ data, jobId }) {
  localStorage.setItem(`trackingInfoStepTwo_${jobId}`, JSON.stringify(data))
}

function handleSubmitJobClientFormStepTwo({ data, jobId }) {
  localStorage.setItem(
    `${JOB_CLIENT_FORM_STEP_TWO_ID}${jobId}`,
    JSON.stringify(data),
  )
}

function* handleSubmitJobClientFormStepThree({ data, jobId }) {
  const stepOne = JSON.parse(
    localStorage.getItem(`${JOB_CLIENT_FORM_STEP_ONE_ID}${jobId}`),
  )
  const stepTwo = JSON.parse(
    localStorage.getItem(`${JOB_CLIENT_FORM_STEP_TWO_ID}${jobId}`),
  )

  let additionalInformation = stepTwo.extraInfo || null
  if (VALUE_HABITATION_APARTMENT === stepTwo?.habitation) {
    additionalInformation = `Digicode: ${
      stepTwo.digicode || 'N/A'
    }\nInterphone: ${stepTwo?.intercom || 'N/A'}\nEtage: ${
      stepTwo?.stage || 'N/A'
    }\nCommentaire client: ${stepTwo?.extraInfo || 'N/A'}`
  }

  try {
    yield handleRequest({
      requestActions: submitJobClientForm,
      promise: call(
        client.post,
        `/engine/jobs/${jobId}/requirements/transition/process_data`,
        {
          worksite: {
            order: {
              customer: {
                firstName: stepOne.firstName,
                lastName: stepOne.lastName,
                email: stepOne.email,
                phoneNumber: stepOne.phone,
              },
            },
            address: {
              street: stepTwo.address.split(`, ${stepTwo.city}`).join(''),
              city: stepTwo.city,
              postcode: stepTwo.postcode,
              countryCode: 'FR',
              type: stepTwo.habitation,
              elevator: stepTwo.elevator || false,
              parking: stepTwo.parking || false,
              additionalInformation,
              rooms: checkboxArrayToObject(data.room, SPECIFIC_ROOM),
              floors: checkboxArrayToObject(data.floor, SPECIFIC_FLOOR),
            },
          },
        },
      ),
      actionParams: {
        data,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleGetStepFourFormQuestions({ jobId }) {
  yield handleRequest({
    requestActions: getStepFourFormQuestions,
    promise: call(
      client.get,
      `/engine/jobs/${jobId}/checklist_items?list=product_requirements`,
    ),
  })
}

function* handleSubmitJobClientFormStepFour({ data, jobId }) {
  try {
    yield handleRequest({
      requestActions: submitJobClientFormPackageCheckin,
      promise: call(
        client.put,
        `/engine/jobs/${jobId}/checklist_items/product_requirements`,
        data,
      ),
    })
    localStorage.removeItem(`${JOB_CLIENT_FORM_STEP_ONE_ID}${jobId}`)
    localStorage.removeItem(`${JOB_CLIENT_FORM_STEP_TWO_ID}${jobId}`)
    localStorage.removeItem(`${JOB_CLIENT_FORM_STEP_THREE_ID}${jobId}`)
    localStorage.removeItem(`${JOB_CLIENT_FORM_STEP_FOUR_ID}${jobId}`)
    yield handleRequest({
      requestActions: getJobCustomer,
      promise: call(client.get, `/engine/jobs/${jobId}`),
    })
  } catch (e) {
    console.error(e)
    yield notify('', 'tracking.job.api.error', 'error')
  }
}

function handleSubmitTrackingInformationStepThree({ data, jobId }) {
  const timeslots = data?.timeslots?.map(timeslot => {
    const month = timeslot.date.getMonth() + 1
    const monthDigit = `${month}`.toString().length

    return {
      date: transformDate(
        `${timeslot.date.getDate()}/${
          monthDigit === 1 ? `0${month}` : month
        }/${timeslot.date.getFullYear()}`,
        DATE_FORMAT.ISO_INITIAL_TIMEZONE,
        DATE_FORMAT.FR_LONG,
      ),
      timeslot: timeslot.timeslot,
    }
  })

  localStorage.setItem(
    `trackingInfoStepThree_${jobId}`,
    JSON.stringify({ timeslots }),
  )
}

function* handleUploadHoPictureToCloudinary({ file, jobId }) {
  try {
    const result = yield readAsDataURL(file)
    const base64result = result.split(',')[1]
    const data = {
      origin: PICTURE_ORIGIN_JOB_TRACKING,
      file: base64result,
    }
    yield handleRequest({
      requestActions: uploadHoPictureToCloudinary,
      promise: call(client.post, `/engine/jobs/${jobId}/pictures`, data),
    })
  } catch (e) {
    yield notify('', 'server_error', 'error')
  }
}

function* handleDeleteJobPicture({ jobPictureId }) {
  try {
    yield handleRequest({
      requestActions: deleteJobPicture,
      promise: call(client.delete, `/engine/job_pictures/${jobPictureId}`),
      actionParams: jobPictureId,
    })
  } catch (e) {
    yield notify('', 'server_error', 'error')
  }
}

function* handleSaveTrackingFillInHoInformation({ payload }) {
  const stepOne = JSON.parse(
    localStorage.getItem(`trackingInfoStepOne_${payload}`),
  )
  const stepTwo = JSON.parse(
    localStorage.getItem(`trackingInfoStepTwo_${payload}`),
  )
  const stepThree = JSON.parse(
    localStorage.getItem(`trackingInfoStepThree_${payload}`),
  )

  let additionalInformation = stepTwo.extraInfo || null
  if (VALUE_HABITATION_APARTMENT === stepTwo?.habitation) {
    additionalInformation = `Digicode: ${
      stepTwo.digicode || 'N/A'
    }\nInterphone: ${stepTwo?.intercom || 'N/A'}\nEtage: ${
      stepTwo?.stage || 'N/A'
    }\nCommentaire client: ${stepTwo?.extraInfo || 'N/A'}`
  }

  try {
    yield handleRequest({
      requestActions: saveTrackingFillInHoInformation,
      promise: call(syliusClient.put, `/order-api/orders/${payload}`, {
        email: stepOne.email,
        timeSlots: formatDatesHo(stepThree),
        shippingAddress: {
          firstName: stepOne.firstName,
          lastName: stepOne.lastName,
          gender: stepOne.gender,
          city: stepTwo.city,
          street: stepTwo.address.split(`, ${stepTwo.city}`).join(''),
          postCode: stepTwo.postcode,
          type: stepTwo.habitation,
          phoneNumber: stepOne.phone,
          additionalInformation,
          elevator: stepTwo.elevator || false,
          parking: stepTwo.parking || false,
        },
      }),
      actionParams: {
        payload,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

function handleSaveTrackingFillInHoInformationSuccess({ actionParams }) {
  localStorage.removeItem(`trackingInfoStepOne_${actionParams.payload}`)
  localStorage.removeItem(`trackingInfoStepTwo_${actionParams.payload}`)
  localStorage.removeItem(`trackingInfoStepThree_${actionParams.payload}`)
}

function* handleMultipleUploadHoFile({ files, jobId, origin }) {
  if (Array.isArray(files)) {
    try {
      yield handleRequest({
        requestActions: multipleUploadHoFile,
        promise: call(multipleUpload, files, jobId, origin),
      })
    } catch (e) {
      yield notify(e, 'tracking.job.file.upload.error', 'error')
    }
  } else {
    yield notify('', 'tracking.job.file.upload.error', 'error')
  }
}

function* handleJobClientStepperTransitionRequest({
  jobIri,
  transition,
  data,
}) {
  try {
    yield handleRequest({
      requestActions: jobClientStepperTransition,
      promise: call(
        client.post,
        `${jobIri}/requirements/transition/${transition}`,
        data,
      ),
    })

    yield handleGetJobs({ jobIri })
  } catch (e) {
    yield notify('', 'client.form.request.failure.message', 'error')
  }
}

const multipleUpload = function (files, jobId, origin) {
  const filesUploaded = []
  return Promise.all(
    files.map(file => {
      const base64result = file.base64.split(',')[1]
      const data = {
        origin: origin || PICTURE_ORIGIN_JOB_TRACKING,
        file: base64result,
      }
      return client.post(`/engine/jobs/${jobId}/pictures`, data)
    }),
  ).then(
    result => {
      result.map(res => filesUploaded.push(res.data))
      return { data: filesUploaded }
    },
    reason => {
      throw reason
    },
  )
}

function* handleSubmitNewTimeslotsForm({ jobId, data }) {
  try {
    yield handleRequest({
      requestActions: submitNewTimeslotsForm,
      promise: call(
        client.put,
        `/engine/jobs/${jobId}/timeslots`,
        formatDatesHo(data),
      ),
    })
    yield handleRequest({
      requestActions: getJobCustomer,
      promise: call(client.get, `/engine/jobs/${jobId}`),
    })
  } catch (e) {
    yield notify('', 'tracking.job.api.error', 'error')
  }
}

function* handleGetReceiptTypes() {
  try {
    yield handleRequest({
      requestActions: getReceiptTypes,
      promise: call(client.get, `/engine/jobs/receipt/reserves-types`),
    })
  } catch (e) {
    yield notify('', 'tracking.job.api.error', 'error')
  }
}

function* handleSendReservesRequest({ data, jobId }) {
  yield put(setOpenYousignIsLoading(true))
  const payload = {
    receiptValidated: true,
  }
  if (data.reservesField !== WITHOUT_RESERVES) {
    payload.reserves = data.reserves
    payload.reserveType = data.reserveType
  }
  try {
    yield handleRequest({
      requestActions: sendReserves,
      promise: call(client.put, `/engine/jobs/${jobId}`, payload),
      actionParams: {
        jobId,
      },
    })
  } catch (e) {
    yield notify('', 'tracking.job.api.error', 'error')
    yield put(setOpenYousignIsLoading(false))
  }
}

function* handleSendReservesSuccess({ actionParams }) {
  yield put(setOpenYousignIsLoading(true))
  const jobId = actionParams.jobId

  try {
    const response = yield* handleRequest({
      requestActions: getReceiptWithReserves,
      promise: call(client.get, `/engine/jobs/${jobId}/receipt/signature`),
    })
    if (response.data.members[0].signableLink) {
      window.open(response.data.members[0].signableLink, '_blank')
    }
    yield put(setOpenYousignIsLoading(false))
    location.reload()
  } catch (e) {
    yield notify('', 'tracking.job.api.error', 'error')
    yield put(setOpenYousignIsLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(MULTIPLE_UPLOAD_HO_FILE.REQUEST, handleMultipleUploadHoFile),
    takeLatest(GET_JOB.REQUEST, handleGetJobs),
    takeLatest(GET_ALL_JOBS, handleGetAllJobs),
    takeLatest(GET_JOBS_CUSTOMER.REQUEST, handleGetJobsCustomer),
    takeLatest(GET_JOB_CUSTOMER.REQUEST, handleGetJobCustomer),
    takeLatest(GET_JOB_CUSTOMER.FAILURE, handleGetJobCustomerFailure),
    takeLatest(JOBS_REQ.REQUEST, handleJobsRequest),
    takeLatest(
      JOB_ACCEPT_TRANSITION_REQ.REQUEST,
      handleAcceptJobTransitionRequest,
    ),
    takeLatest(
      JOB_DECLINE_TRANSITION_REQ.REQUEST,
      handleDeclineJobTransitionRequest,
    ),
    takeLatest(LOCATION_CHANGE, handleLocationChange),
    takeLatest(UPDATE_JOB.REQUEST, handleUpdateJobRequest),
    takeLatest(VALIDATE_RECEIPT.REQUEST, handleValidateReceipt),
    takeLatest(GET_RECEIPT_JOB.REQUEST, handleGetReceiptJob),
    takeLatest(OPEN_RECEIPT_JOB_FILE, handleOpenReceiptJobNewWindow),
    takeLatest(
      GET_RECEIPT_JOB_FILE.SUCCESS,
      handleGetReceiptJobNewWindowSuccess,
    ),
    takeLatest(GET_RECEIPT_SIGNATURE.REQUEST, handleGetReceiptSignature),
    takeLatest(GET_INVOICE_JOB.REQUEST, handleGetInvoiceJob),
    takeLatest(TRANSITION_INVOICE_JOB.REQUEST, handleTransitionInvoiceJob),
    takeLatest(GET_JOB_TOKEN_FIREBASE.REQUEST, handleGetJobTokenFirebase),
    takeLatest(
      SUBMIT_VERIFY_CHECKING_CONDITION_FORM.REQUEST,
      handleSubmitVerifyCheckingConditionForm,
    ),
    takeLatest(
      SUBMIT_VERIFY_CHECKING_CONDITION_FORM.SUCCESS,
      handleSubmitVerifyCheckingConditionFormSuccess,
    ),
    takeLatest(
      SUBMIT_VERIFY_CHECKING_CONDITION_FORM.FAILURE,
      handleSubmitVerifyCheckingConditionFormFailure,
    ),
    takeLatest(
      GET_CHECKING_CONDITION_LIST.REQUEST,
      handleGetCheckingConditionList,
    ),
    takeLatest(GET_PRO_PHONE_NUMBER.REQUEST, handleGetProPhoneNumber),
    takeLatest(SHARE_JOB_INFOS.REQUEST, handleShareJobInfos),
    takeLatest(
      SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_ONE,
      handleSubmitTrackingInformationStepOne,
    ),
    takeLatest(
      SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_TWO,
      handleSubmitTrackingInformationStepTwo,
    ),
    takeLatest(
      SUBMIT_TRACKING_FILL_IN_HO_INFORMATION_FORM_STEP_THREE,
      handleSubmitTrackingInformationStepThree,
    ),
    takeLatest(
      SUBMIT_JOB_CLIENT_FORM_STEP_ONE,
      handleSubmitJobClientFormStepOne,
    ),
    takeLatest(
      SUBMIT_JOB_CLIENT_FORM_STEP_TWO,
      handleSubmitJobClientFormStepTwo,
    ),
    takeLatest(
      SUBMIT_JOB_CLIENT_FORM_STEP_THREE,
      handleSubmitJobClientFormStepThree,
    ),
    takeLatest(
      GET_STEP_FOUR_FORM_QUESTIONS.REQUEST,
      handleGetStepFourFormQuestions,
    ),
    takeLatest(
      SUBMIT_JOB_CLIENT_FORM_STEP_FOUR,
      handleSubmitJobClientFormStepFour,
    ),
    takeLatest(
      SUBMIT_JOB_CHECKIN_FORM_STEP_ONE,
      handleSubmitJobCheckinFormStepOne,
    ),
    takeLatest(
      SUBMIT_JOB_CHECKIN_FORM_STEP_TWO,
      handleSubmitJobCheckinFormStepTwo,
    ),
    takeLatest(
      SUBMIT_JOB_CHECKIN_FORM_STEP_THREE,
      handleSubmitJobCheckinFormStepThree,
    ),
    takeLatest(
      SUBMIT_NEW_DELIVERY_DATE_REQ.REQUEST,
      handleSubmitNewDeliveryDate,
    ),
    takeLatest(
      UPLOAD_HO_PICTURE_TO_CLOUDINARY.REQUEST,
      handleUploadHoPictureToCloudinary,
    ),
    takeLatest(
      SAVE_TRACKING_FILL_IN_HO_INFORMATION.REQUEST,
      handleSaveTrackingFillInHoInformation,
    ),
    takeLatest(
      SAVE_TRACKING_FILL_IN_HO_INFORMATION.SUCCESS,
      handleSaveTrackingFillInHoInformationSuccess,
    ),
    takeLatest(DELETE_JOB_PICTURE.REQUEST, handleDeleteJobPicture),
    takeLatest(SUBMIT_NEW_TIMESLOTS_FORM.REQUEST, handleSubmitNewTimeslotsForm),
    takeLatest(
      JOB_CLIENT_STEPPER_TRANSITION_REQ.REQUEST,
      handleJobClientStepperTransitionRequest,
    ),
    takeLatest(GET_RECEIPT_TYPES.REQUEST, handleGetReceiptTypes),
    takeLatest(SEND_RESERVES.REQUEST, handleSendReservesRequest),
    takeLatest(SEND_RESERVES.SUCCESS, handleSendReservesSuccess),
  ])
}
